import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import classnames from 'classnames';

import Layout from '../components/layout';
import SEO from '../components/seo';

const YoutubePage = ({ pageContext }) => {
  const { videoId, title } = pageContext;
  const formId = `yt-${videoId}`;

  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    window.submitContact = () => {
      document.getElementById(formId).submit();
    };

    if (window.location.search.includes('error=email')) {
      setEmailError(true);
    }
  }, [formId]);

  const onClick = event => {
    if (document.getElementById(formId).checkValidity()) {
      event.preventDefault();
      window.grecaptcha.reset();
      window.grecaptcha.execute();
    }
  };

  return (
    <Layout>
      <SEO title={title} />
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async defer />
      </Helmet>
      <h1 className="title">{title}</h1>
      <hr />
      <div className="content">
        <p>Thanks so much for your interest in downloading the PDF resource attached to the video.</p>
      </div>
      <div className="columns">
        <div className="column">
          <form id={formId} name={formId} method="post" action="/.netlify/functions/youtube">
            <div className="field">
              <label className="label" htmlFor="name">
                Name
              </label>
              <div className="control">
                <input className="input" type="text" id="name" name="name" required />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="email">
                Email
              </label>
              <div className="control">
                <input
                  className={classnames('input', { 'is-danger': emailError })}
                  type="text"
                  id="email"
                  name="email"
                  required
                />
              </div>
              {emailError && <p className="help is-danger">Please provide a valid email address.</p>}
            </div>
            <div className="container">
              <p className="is-size-7">
                By clicking on the button, you agree with our <Link to="/privacy-policy/">Privacy Policy</Link> and{' '}
                <Link to="/terms-of-use/">Terms of Use</Link>.
              </p>
              <p className="is-size-7">
                You also agree to receive occasional emails to inform you about our new videos. It is very easy to
                unsubscribe should no longer wish to receive these by clicking the "Unsubscribe" link available at the
                bottom of the email.
              </p>
            </div>
            <div className="field is-grouped">
              <div className="control">
                <div
                  className="g-recaptcha"
                  data-sitekey="6Lct_NYUAAAAAFOoCLfyW5mHpUN8x3bbsBGHl-UN"
                  data-size="invisible"
                  data-callback="submitContact"
                />
                <input type="hidden" id="download" name="download" value={formId} />
                <button className="button is-link" onClick={onClick}>
                  Download the FREE checklist
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="column" />
      </div>
    </Layout>
  );
};

export default YoutubePage;
